import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import * as styles from '@/styles/blog/Blog.module.css'

const Blogs = ({ data }) => {
  const [blogDatas, _] = useState(data?.allMicrocmsBlog?.edges)
  return (
    <div className={styles.container}>
      <h1 className={styles.heading}>人気記事</h1>
      <ul className={styles.blogList}>
        {blogDatas &&
          blogDatas.map((value, index) => {
            const nodes = value.node
            return (
              <li key={index} className={styles.blogItem}>
                <Link
                  to={`/blog-details/${nodes.id}`}
                  state={{ blogDatas }}
                  className={styles.blogLink}
                >
                  <img
                    alt={nodes.title}
                    src={nodes.image.url}
                    className={styles.blogImage}
                  />
                  <div className={styles.blogContent}>
                    <h2 className={styles.blogTitle}>{nodes.title}</h2>
                  </div>
                </Link>
              </li>
            )
          })}
      </ul>
    </div>
  )
}

export default Blogs

export const query = graphql`
  query {
    allMicrocmsBlog {
      edges {
        node {
          id
          title
          image {
            url
          }
        }
      }
    }
  }
`
